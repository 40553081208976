/* eslint-disable camelcase */
import * as Yup from 'yup';
import CheckBox from 'app/components/partials/checkbox';
import { clientEnv } from 'config/env';
import { clientFetch } from 'app/utilities/fetch';
import Icon from 'app/components/partials/icon';
import Input from 'app/components/partials/input';
import Loader from 'app/components/partials/loader';
import Modal from 'app/components/partials/modal';
import PropTypes from 'prop-types';
import RecaptchaPolicy from 'app/components/partials/recaptcha-policy';
import { setUserAction } from 'app/ducks/auth';
import { useFormik } from 'formik';
import { ENDPOINTS, ERROR_MESSAGE, RESPONSE } from 'config/api';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Account = ({ data }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [resetPasswordStatus, setResetPasswordStatus] = useState(false);

    const dispatch = useDispatch();

    const { name: title, short_description: shortDescription } = data;
    const {
        first_name: initialFirstName,
        last_name: initialLastName,
        email,
        marketing_opt_in: initialMarketingOptIn
    } = useSelector((state) => state.auth.user);

    const formik = useFormik({
        initialValues: {
            firstname: initialFirstName,
            lastname: initialLastName,
            email,
            marketingOptIn: initialMarketingOptIn
        },
        validationSchema: Yup.object({
            firstname: Yup.string().required('This is a required field'),
            lastname: Yup.string().required('This is a required field'),
        }),
        onSubmit: async(values, { setStatus, setSubmitting }) => {
            setStatus();

            try {
                const response = await clientFetch(ENDPOINTS.PORTAL_USER, {
                    method: 'POST',
                    body: JSON.stringify({
                        first_name: values.firstname,
                        last_name: values.lastname,
                        marketing_opt_in: values.marketingOptIn
                    })
                }, true);

                const successMessage = 'Your account data has been updated.';
                setStatus({ success: successMessage });
                dispatch(setUserAction(response));
            } catch (err) {
                const { error, status } = err;

                const errorMessage =
                    status === RESPONSE.BAD_REQUEST &&
                    error &&
                    error.error &&
                    error.error.message || ERROR_MESSAGE.DEFAULT;

                setStatus({ error: errorMessage });
            } finally {
                setSubmitting(false);
            }
        }
    });

    const submitResetPassword = async(email, captchaToken, setResetPasswordStatus, setIsLoading) => {
        try {
            const response = await clientFetch(ENDPOINTS.PORTAL_SEND_RESET_PASSWORD_LINK, {
                method: 'POST',
                body: JSON.stringify({
                    email: email,
                    captchaToken,
                })
            });

            const successMessage =
                response &&
                response.message ||
                'The link has been sent, please check your email to reset your password.';

            setResetPasswordStatus({ success: successMessage });
        } catch (err) {
            const { error, status } = err;

            const errorMessage =
                status === RESPONSE.BAD_REQUEST &&
                error &&
                error.error &&
                error.error.message || ERROR_MESSAGE.DEFAULT;

            setResetPasswordStatus({ error: errorMessage });
        } finally {
            setIsLoading(false);
        }
    };

    const resetPasswordClickHandler = async() => {
        setIsLoading(true);
        // eslint-disable-next-line no-undef
        grecaptcha.ready(function() {
            // eslint-disable-next-line no-undef
            grecaptcha.execute(clientEnv.RECAPTCHA_KEY, { action: 'forgot_password' }).then(function(token) {
                // Pass recaptcha verify token for backend to validate
                submitResetPassword(email, token, setResetPasswordStatus, setIsLoading);
            });
        });
    };

    const formHasNoChange = () => {
        const { firstname, lastname, marketingOptIn } = formik.values;

        return firstname === initialFirstName && lastname === initialLastName && marketingOptIn === initialMarketingOptIn;
    };

    const renderModalContent = () => {
        const isResetPasswordSuccess = resetPasswordStatus && resetPasswordStatus.success;

        return (
            <>
                <div className={`icon-wrapper ${isResetPasswordSuccess ? 'success' : 'error'}`}>
                    {isResetPasswordSuccess ?
                        <Icon name="check" title="Success" /> :
                        <Icon name="close" title="Error" />
                    }
                </div>
                <p>{resetPasswordStatus.success || resetPasswordStatus.error}</p>
            </>
        );
    };

    const isButtonsDisabled = isLoading || formik.isSubmitting;

    return (
        <div className="members-portal-account || constrain-width large">
            <h2>{title}</h2>
            <p>{shortDescription}</p>
            <form className="account-settings-form" onSubmit={formik.handleSubmit}>
                <div className="section">
                    <div className="form-group space-between">
                        <Input
                            label="First name"
                            type="text"
                            className="input"
                            name="firstname"
                            id="firstname"
                            placeholder=""
                            value={formik.values.firstname}
                            onChangeHandler={formik.handleChange}
                            onBlurHandler={formik.handleBlur}
                            isRequired
                            error={formik.touched.firstname && formik.errors.firstname}
                        />
                        <Input
                            label="Last name"
                            type="text"
                            className="input"
                            name="lastname"
                            id="lastname"
                            placeholder=""
                            value={formik.values.lastname}
                            onChangeHandler={formik.handleChange}
                            onBlurHandler={formik.handleBlur}
                            isRequired
                            error={formik.touched.lastname && formik.errors.lastname}
                        />
                        <Input
                            label="Email address"
                            type="email"
                            className="input"
                            name="email"
                            id="email"
                            value={formik.values.email}
                            readOnly
                            disabled
                        />
                        <CheckBox
                            name="marketingOptIn"
                            type="checkbox"
                            inputType="large"
                            label="Yes, please keep me informed with first access to the latest Zoo news, exclusive member benefits, exciting events, and special giveaways!"
                            value={formik.values.marketingOptIn}
                            onChangeHandler={formik.handleChange}
                        />
                    </div>
                </div>
                {formik.status && formik.status.error && <div className="error-message">{formik.status.error}</div>}
                {formik.status && formik.status.success && <div className="success-message">{formik.status.success}</div>}
                <button
                    className="button primary || reset-password"
                    onClick={resetPasswordClickHandler}
                    type="button"
                    disabled={isButtonsDisabled}
                >
                    {isLoading ? <Loader type="small" /> : 'Reset password'}
                </button>
                <button
                    className="button || save-changes"
                    type="submit"
                    disabled={isButtonsDisabled || formHasNoChange()}
                >
                    {formik.isSubmitting ? <Loader type="small" /> : 'Save changes'}
                </button>
                <RecaptchaPolicy />
            </form>
            <Modal isVisible={Boolean(resetPasswordStatus)} onCloseHandler={() => setResetPasswordStatus(false)}>
                {renderModalContent()}
            </Modal>
        </div>
    );
};

Account.propTypes = {
    data: PropTypes.object.isRequired
};

export default Account;
