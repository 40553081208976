import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { getUnitPrice, TICKETS_TYPE_DEFAULT_MEMBERSHIP, TICKETS_TYPE_PARAM } from 'app/utilities/buy-memberships';

const ProductsSelectedList = ({ priceRows = null }) => {
    const {
        productsSelected,
        passHolders,
        renewDiscountPct,
        hasRenewDiscount
    } = useSelector((state) => state.buyMemberships);

    // Extract query parameters directly from Redux state
    const search = useSelector((state) => state.router.location.search);
    const selectedProduct = new URLSearchParams(search).get(TICKETS_TYPE_PARAM) || TICKETS_TYPE_DEFAULT_MEMBERSHIP;
    // Get products and discounts based on the last path segment, defaulting to 'membership' if not found
    const { products = [] } = useSelector((state) => {
        const bundles = state.tickets.membership || [];

        return (
            bundles.find((bundle) => bundle.name === selectedProduct) ||
            bundles.find((bundle) => bundle.name === TICKETS_TYPE_DEFAULT_MEMBERSHIP) ||
            {}
        );
    });
    // TODO: always let parent generate the data to pass in
    let displayData,
        showRenewDiscount = hasRenewDiscount;
    if (priceRows) {
        // For complete step, display pricing details from receipt given by backend
        displayData = priceRows;
        // And don't calculate renew discount as it's already included
        showRenewDiscount = false;
    } else {
        // For buy membership review, display passholders data from redux store
        displayData = passHolders.map(({ product, passIndexByProduct, formFields }) => {
            const { price } = products.find(({ name }) => name.toLowerCase() === product.toLowerCase()) || {};
            const label = `${product} ${passIndexByProduct + 1}`;
            const firstName = formFields.find(({ fieldName }) => fieldName === 'first_name').value;
            const lastName = formFields.find(({ fieldName }) => fieldName === 'last_name').value;
            const personName = `${firstName} ${lastName}`;

            return { label, personName, price };
        });

        // eslint-disable-next-line camelcase
        const guestProductsSelected = productsSelected.filter(({ is_guest }) => is_guest);

        if (guestProductsSelected && guestProductsSelected.length) {
            guestProductsSelected.map(({ product, price }) => {
                displayData.push({ label: product, personName: '', price });
            });
        }
    }

    const renderProductsSelectedList = () => {
        return displayData.map(({ label, personName, price }, key) => (
            <tr className="select-product-quantities-row" key={key}>
                <td className="select-product-quantities-name">
                    <div className="select-product-quantities-name-label">{label}</div>
                    {/* This list is also used when purchasing gift memberships (they don't need to fill in names) */}
                    {personName &&
                        <div className="select-product-quantities-name-description">{personName}</div>
                    }
                </td>
                <td className="select-product-quantities-price">
                    {showRenewDiscount &&
                        <span className="select-product-quantities-price-old">${price}</span>
                    }
                    <strong className={showRenewDiscount ? 'select-product-quantities-price-final' : ''}>${getUnitPrice(price, showRenewDiscount ? renewDiscountPct : 0)}</strong>
                </td>
            </tr>
        ));
    };

    return (
        <table className="select-product-quantities">
            <tbody>
                {renderProductsSelectedList()}
            </tbody>
        </table>
    );
};

ProductsSelectedList.propTypes = {
    priceRows: PropTypes.array
};

export default ProductsSelectedList;
