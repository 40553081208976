import AuthenticatedRoute from './authenticated-route';
import BuyMembershipPage from './components/pages/buy-membership-page';
import { BuyMembershipProvider } from 'app/contexts/buy-membership-context';
import ContentPage from './components/pages/content-page.container';
import DonationPaymentPage from './components/pages/donation-payment-page.container';
import DonationThankYouPage from './components/pages/donation-thank-you-page';
import Footer from './components/partials/footer.container';
import ForgotPasswordPage from './components/pages/member/auth/forgot-password-page';
import GiftMembershipPage from './components/pages/gift-membership-page';
import Header from './components/partials/header.container';
import { initialUserVerifyAction } from 'app/ducks/auth';
import LoginPage from './components/pages/member/auth/login-page';
import MemberPortalPage from './components/pages/member/portal';
import NewsletterUnsubscribePage from './components/pages/newsletter-unsubscribe-page';
import NotFoundPage from './components/pages/error/not-found-page';
import ResetPasswordPage from './components/pages/member//auth/reset-password-page';
import ScrollToTop from './components/partials/scroll-to-top';
import SearchDialog from './components/partials/search-dialog.container';
import StyleGuidePage from './components/pages/style-guide-page';
import throttleUpdateWhileRouting from './utilities/throttle-update-while-routing';
import { useDispatch } from 'react-redux';
import { FRONTEND_ROUTES, MEMBERS_PORTAL_ROUTES } from 'app/utilities/routes';
import { Outlet, Route, Routes as SwitchRoutes } from 'react-router-dom';
import React, { useEffect } from 'react';

const Routes = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(initialUserVerifyAction());
    }, []);

    return (
        <div className="base">
            <ScrollToTop />
            <Header />
            <SearchDialog />
            <SwitchRoutes>
                {/* Custom */}
                <Route path={FRONTEND_ROUTES.STYLE_GUIDE} element={<StyleGuidePage />} />
                <Route path={FRONTEND_ROUTES.DONATE_PAYMENT} element={<DonationPaymentPage />} />
                <Route path={FRONTEND_ROUTES.DONATE_THANK_YOU} element={<DonationThankYouPage />} />
                <Route path={FRONTEND_ROUTES.NEWSLETTER_UNSUBSCRIBE} element={<NewsletterUnsubscribePage />} />
                {/* Zoo portal */}
                {/* Credential */}
                <Route path={FRONTEND_ROUTES.PORTAL_LOGIN} element={<LoginPage />} />
                <Route path={FRONTEND_ROUTES.PORTAL_FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
                <Route path={FRONTEND_ROUTES.PORTAL_RESET_PASSWORD} element={<ResetPasswordPage />} />
                {/* Private portal routes */}
                <Route path={MEMBERS_PORTAL_ROUTES.BASE} element={
                    <AuthenticatedRoute redirectTo={FRONTEND_ROUTES.PORTAL_LOGIN}>
                        <MemberPortalPage />
                    </AuthenticatedRoute>
                } />
                {/* Membership related routes */}
                <Route element={
                    <BuyMembershipProvider>
                        <Outlet />
                    </BuyMembershipProvider>
                }>
                    {/* Buy membership routes */}
                    <Route path={FRONTEND_ROUTES.BUY_MEMBERSHIP} element={<BuyMembershipPage />} />
                    <Route path={FRONTEND_ROUTES.BUY_MEMBERSHIP_THANK_YOU} element={<BuyMembershipPage />} />
                </Route>
                <Route element={
                    <BuyMembershipProvider isRenewPass>
                        <Outlet />
                    </BuyMembershipProvider>
                }>
                    {/* authenticated renew membership route */}
                    <Route path={FRONTEND_ROUTES.RENEW_MEMBERSHIP} element={
                        <AuthenticatedRoute redirectTo={FRONTEND_ROUTES.PORTAL_LOGIN}>
                            <BuyMembershipPage />
                        </AuthenticatedRoute>
                    } />
                    <Route path={FRONTEND_ROUTES.RENEW_MEMBERSHIP_THANK_YOU} element={<BuyMembershipPage />} />
                </Route>
                <Route element={
                    <BuyMembershipProvider isGifting>
                        <Outlet />
                    </BuyMembershipProvider>
                }>
                    {/* Gift membership route */}
                    <Route path={FRONTEND_ROUTES.GIFT_MEMBERSHIP} element={<GiftMembershipPage />} />
                    <Route path={FRONTEND_ROUTES.GIFT_MEMBERSHIP_THANK_YOU} element={<GiftMembershipPage />} />
                </Route>
                {/* API Based Routes */}
                <Route path="/" element={<ContentPage />} />
                <Route path="/:slug" element={<ContentPage />} />
                <Route path="/:parent/:slug" element={<ContentPage />} />
                {/* Catch */}
                <Route path="*" element={<NotFoundPage />} />
            </SwitchRoutes>
            <Footer />
        </div>
    );
};

export default throttleUpdateWhileRouting(Routes);
