import Banner from 'app/components/partials/banner';
import { dataLayerPush } from 'app/utilities/gtm';
import Helmet from 'react-helmet';
import Loader from 'app/components/partials/loader';
import ProductsInfoBanner from 'app/components/partials/buy-memberships/products-info-banner';
import ProgressBar from 'app/components/partials/buy-memberships/progress-bar';
import PropTypes from 'prop-types';
import { setCurrentStepIndexAction } from 'app/ducks/buy-memberships';
import { useBuyMembership } from 'app/contexts/buy-membership-context';
import {
    BUY_MEMBERSHIP_STEPS,
    MEMBER_DETAILS_STEP_INDEX,
    REVIEW_STEP_INDEX
} from 'config/buy-memberships-steps';
import { getCosts, TICKETS_TYPE_DINO } from 'app/utilities/buy-memberships';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const MembershipContent = ({
    currentStep,
    products,
    renewPassId,
    renewPassIdParam,
    costs,
    stepClickHandler
}) => {
    const { saveCosts, totalCosts, costsAfterDiscount } = costs;
    const displayInfoBanner = [MEMBER_DETAILS_STEP_INDEX, REVIEW_STEP_INDEX].includes(currentStep.index);
    const CurrentStepComponent = currentStep.component;

    return (
        <section className="constrain-width large || block || buy-membership-block">
            {displayInfoBanner && (
                <ProductsInfoBanner
                    stepClickHandler={stepClickHandler}
                    costsAfterDiscount={costsAfterDiscount}
                />
            )}
            {products && products.length > 0 && (!renewPassIdParam || renewPassId) && (
                <div className="content">
                    <CurrentStepComponent
                        saveCosts={saveCosts}
                        totalCosts={totalCosts}
                        costsAfterDiscount={costsAfterDiscount}
                        stepClickHandler={stepClickHandler}
                    />
                </div>
            )}
        </section>
    );
};

MembershipContent.propTypes = {
    currentStep: PropTypes.object.isRequired,
    products: PropTypes.array,
    renewPassId: PropTypes.string,
    renewPassIdParam: PropTypes.string,
    costs: PropTypes.shape({
        saveCosts: PropTypes.number,
        totalCosts: PropTypes.number,
        costsAfterDiscount: PropTypes.number
    }).isRequired,
    stepClickHandler: PropTypes.func.isRequired
};

const BuyMembershipPage = () => {
    const {
        currentStepIndex,
        productsSelected,
        renewPassId,
        renewDiscountPct,
        extraDiscount
    } = useSelector((state) => state.buyMemberships);

    const { products, discounts, isLoading, renewPassIdParam, selectedProductName, isRenewPass } = useBuyMembership();

    const dispatch = useDispatch();

    const currentStep = BUY_MEMBERSHIP_STEPS.find((step) => step.index === currentStepIndex);
    const costs = getCosts(productsSelected, discounts, renewDiscountPct, extraDiscount);

    const stepClickHandler = (index) => dispatch(setCurrentStepIndexAction(index));

    // Resolve dynamic title for customise page
    const { title, subtitle } = getPageTitles(currentStep, selectedProductName, isRenewPass);

    useEffect(() => {
        window.scrollTo(0, 0);
        dataLayerPush({
            id: 'micro_conversion',
            event: 'membershipTracking',
            action: isRenewPass ? 'Renew' : 'Purchase',
            label: currentStep.name
        });
    }, [currentStepIndex]);

    if (isLoading()) {
        return (
            <div className="page || page-view">
                <div className="loader-container pad">
                    <Loader />
                </div>
            </div>
        );
    }

    return (
        <main role="main" className="main">
            <Helmet>
                <title>Buy membership</title>
            </Helmet>
            <div className="page || page-view">
                <Banner title={title} subtitle={subtitle} />
                <ProgressBar
                    activeStepIndex={currentStepIndex}
                    items={BUY_MEMBERSHIP_STEPS}
                    stepClickHandler={stepClickHandler}
                />
                <MembershipContent
                    currentStep={currentStep}
                    products={products}
                    renewPassId={renewPassId}
                    renewPassIdParam={renewPassIdParam}
                    costs={costs}
                    stepClickHandler={stepClickHandler}
                />
            </div>
        </main>
    );
};

export default BuyMembershipPage;

// For customise step only
// Dynamic page title and subtitle depending on whether we are renewing, or purchasing a dino pass or annual pass
// eslint-disable-next-line require-jsdoc
function getPageTitles(currentStep, bundle, isRenew) {
    if (currentStep.key === 'customise') {
        if (isRenew) {
            return {
                title: 'Renew your Annual Pass Membership',
                subtitle: 'Get 25% off an Annual Pass Membership when you renew within 6 months, unlimited entry to Auckland Zoo for 364 days a year and other great benefits.'
            };
        }

        switch (bundle) {
            case TICKETS_TYPE_DINO:
                return {
                    title: 'Create your Dinosaur Pass Membership',
                    subtitle: 'This membership gives you unlimited entry to Auckland Zoo for 183 days a year and other great benefits. Available for a short time only, until 30 June 2025.',
                };
            default:
                // annual pass
                return {
                    title: 'Create your Annual Pass Membership',
                    subtitle: 'This membership gives you unlimited entry to Auckland Zoo for 364 days a year and other great benefits.',
                };
        }
    }

    return {
        title: currentStep.title,
        subtitle: currentStep.subtitle,
    };
}
