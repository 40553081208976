import Banner from 'app/components/partials/banner';
import { dataLayerPush } from 'app/utilities/gtm';
import { GIFT_MEMBERSHIP_STEPS } from 'config/gift-memberships-steps';
import Helmet from 'react-helmet';
import Loader from 'app/components/partials/loader';
import ProductsInfoBanner from '../partials/buy-memberships/products-info-banner';
import ProgressBar from 'app/components/partials/buy-memberships/progress-bar';
import { useSearchParams } from 'react-router-dom';
import { getCosts, TICKETS_TYPE_DEFAULT_MEMBERSHIP, TICKETS_TYPE_PARAM } from 'app/utilities/buy-memberships';
import {
    GIFTER_DETAILS_STEP_INDEX,
    RECIPIENT_DETAILS_STEP_INDEX,
    REVIEW_STEP_INDEX
} from 'config/gift-memberships-steps-defs';
import {
    initiateUpdateGiftBookingAction,
    setCurrentStepIndexAction,
} from 'app/ducks/buy-memberships';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const GiftMembershipPage = () => {
    const {
        currentStepIndex,
        productsSelected,
        bookingUpdated,
    } = useSelector((state) => state.buyMemberships);
    // Gift membership only allow normal membership products
    // Get products and discounts based on the last path segment, defaulting to 'membership' if not found
    const { products = [], discounts = [] } = useSelector((state) => {
        const bundles = state.tickets.membership || [];

        return (
            bundles.find((bundle) => bundle.name === TICKETS_TYPE_DEFAULT_MEMBERSHIP)
        );
    });
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const currentStep = GIFT_MEMBERSHIP_STEPS.find((step) => step.index === currentStepIndex);
    const CurrentStepComponent = currentStep.component;

    const { totalCosts, saveCosts, costsAfterDiscount } = getCosts(productsSelected, discounts);

    // eslint-disable-next-line no-magic-numbers
    const displayInfoBanner = [GIFTER_DETAILS_STEP_INDEX, RECIPIENT_DETAILS_STEP_INDEX, REVIEW_STEP_INDEX].includes(currentStepIndex);

    const stepClickHandler = (index) => dispatch(setCurrentStepIndexAction(index));

    const extractResultFromUrl = () => searchParams.get('result');

    const pushDataToGa = () => {
        /* ↓ Tracking buy membership tracking events */
        dataLayerPush({
            id: 'micro_conversion',
            event: 'membershipTracking',
            action: 'Purchase', // TODO: add Renew when the renew pass has been implemented
            label: currentStep.name
        });
        /* ↑ Tracking buy membership tracking events */
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        pushDataToGa();
    }, [currentStepIndex]);

    useEffect(async() => {
        const result = extractResultFromUrl();
        dispatch(initiateUpdateGiftBookingAction(result, products));
    }, []);

    if (extractResultFromUrl() && !bookingUpdated) {
        return (
            <div className="page || page-view">
                <div className="loader-container pad">
                    <Loader />
                </div>
            </div>
        );
    }

    return (
        <main role="main" className="main">
            <Helmet>
                <title>Gift membership</title>
            </Helmet>
            <div className="page || page-view">
                <Banner title={currentStep.title} subtitle={currentStep.subtitle} />

                <ProgressBar
                    activeStepIndex={currentStepIndex}
                    items={GIFT_MEMBERSHIP_STEPS}
                    stepClickHandler={stepClickHandler}
                    isGifting
                />
                <section className="constrain-width large || block || buy-membership-block">
                    {displayInfoBanner &&
                        <ProductsInfoBanner
                            stepClickHandler={stepClickHandler}
                            costsAfterDiscount={costsAfterDiscount}
                        />
                    }
                    {/* wait for products, and also wait for renew details to be fetched if required  */}
                    {products && products.length !== 0 && (
                        <div className="content">
                            <CurrentStepComponent
                                saveCosts={saveCosts}
                                totalCosts={totalCosts}
                                costsAfterDiscount={costsAfterDiscount}
                                stepClickHandler={stepClickHandler}
                                isGifting
                            />
                        </div>
                    )}
                </section>
            </div>
        </main>
    );
};

export default GiftMembershipPage;
