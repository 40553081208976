import PropTypes from 'prop-types';
import {
    canPurchaseTicket,
    getAnnualPass,
    TICKETS_TYPE_DEFAULT_MEMBERSHIP,
    TICKETS_TYPE_PARAM
} from 'app/utilities/buy-memberships';
import {
    fetchCartAction,
    initiateRenewPassAction,
    initiateUpdateBookingAction,
    preSelectedPassAction,
    setExtraDiscountAction,
    setRenewPassAction
} from 'app/ducks/buy-memberships';
import { FRONTEND_ROUTES, MEMBERS_PORTAL_ROUTES } from 'app/utilities/routes';
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

const BuyMembershipContext = createContext();

export const BuyMembershipProvider = ({ children, isGifting = false, isRenewPass = false }) => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const search = useSelector((state) => state.router.location.search);
    const selectedProduct = new URLSearchParams(search).get(TICKETS_TYPE_PARAM) || TICKETS_TYPE_DEFAULT_MEMBERSHIP;

    const { products = [], discounts = [], name = '', label } = useSelector((state) => {
        const bundles = state.tickets.membership || [];

        return (
            bundles.find((bundle) => bundle.name === selectedProduct) ||
            bundles.find((bundle) => bundle.name === TICKETS_TYPE_DEFAULT_MEMBERSHIP) ||
            {}
        );
    });

    const { user, isLoading: userIsLoading } = useSelector((state) => state.auth);
    const { bookingUpdated, isCartFetched } = useSelector((state) => state.buyMemberships);

    const cartParam = searchParams.get('cart');
    const renewPassIdParam = searchParams.get('renewPassId');
    const annualPassToRenew = user && getAnnualPass(user.annual_passes, renewPassIdParam);
    const canRenewPass = renewPassIdParam && annualPassToRenew && annualPassToRenew.can_renew;
    // Gift only supports annual pass for now
    const isDefaultMembership = isGifting || selectedProduct === TICKETS_TYPE_DEFAULT_MEMBERSHIP;

    const extractResultFromUrl = () => searchParams.get('result');

    const handleAbandonedCart = () => {
        if (!cartParam) return false;

        if (!user) {
            const redirect = encodeURIComponent(`${FRONTEND_ROUTES.BUY_MEMBERSHIP}?cart=${cartParam}`);
            navigate(`${FRONTEND_ROUTES.PORTAL_LOGIN}?redirect=${redirect}`);

            return true;
        }

        if (isRenewPass) {
            if (canRenewPass) {
                dispatch(initiateRenewPassAction(annualPassToRenew, null, products));
            } else {
                navigate(MEMBERS_PORTAL_ROUTES.RENEW, { replace: true });

                return false;
            }
        }

        dispatch(fetchCartAction(cartParam));

        return true;
    };

    useEffect(() => {
        // Wait for user to finish loading before we fetch abandoned cart
        // Only for annual pass purchase
        if (!userIsLoading && isDefaultMembership) {
            handleAbandonedCart();
        }
    }, [userIsLoading, isDefaultMembership]);

    // Handle purchase annual pass preselect and renewal
    useEffect(() => {
        // Gifting flow don't need to execute this
        if (isGifting) return;

        // Abandoned cart uses separate flow for cleaner logic
        if (cartParam) return;

        const result = extractResultFromUrl();
        dispatch(initiateUpdateBookingAction(result, products));

        if (result) return;

        // Renew membership flow
        if (isRenewPass) {
            if (canRenewPass) {
                const prefill = searchParams.get('prefill');
                dispatch(initiateRenewPassAction(annualPassToRenew, prefill, products));
            } else {
                // If no renewPassIdParam or no pass can be renewed, redirect user to portal renew page
                navigate(MEMBERS_PORTAL_ROUTES.RENEW, { replace: true });
            }
        } else {
            // pre selected products only for new pass purchase
            dispatch(preSelectedPassAction(products));
        }

        // Clear the renew pass store and clear the extra discount when user leaves the page
        return () => {
            dispatch(setRenewPassAction(null, 0));
            dispatch(setExtraDiscountAction(null));
        };
    }, []);

    // Redirect if user is not allowed to purchase a new pass
    useEffect(() => {
        // Gifting flow don't need to execute this
        if (isGifting) return;

        // User won't be redirected when they are renewing or on complete step
        if (isRenewPass || extractResultFromUrl()) return;

        // if user not allow to purchase new tickets
        // user has login and has one or more passs
        const canBuyMemberShip = canPurchaseTicket(user);
        if (!canBuyMemberShip) {
            navigate(MEMBERS_PORTAL_ROUTES.DASHBOARD);
        }
    }, [user]);

    const value = useMemo(() => ({
        isDefaultMembership,
        selectedProductName: name,
        selectedProductLabel: label,
        products,
        discounts,
        isLoading: () => (extractResultFromUrl() && !bookingUpdated) || (cartParam && !isCartFetched),
        canRenewPass,
        cartParam,
        renewPassIdParam,
        user,
        isRenewPass
    }), [
        selectedProduct,
        products,
        discounts,
        bookingUpdated,
        isCartFetched,
        cartParam,
        user,
        canRenewPass,
        isRenewPass,
    ]);

    return (
        <BuyMembershipContext.Provider value={value}>
            {children}
        </BuyMembershipContext.Provider>
    );
};

BuyMembershipProvider.propTypes = {
    children: PropTypes.node.isRequired,
    isGifting: PropTypes.bool,
    isRenewPass: PropTypes.bool,
};

export const useBuyMembership = () => {
    const context = useContext(BuyMembershipContext);
    if (!context) {
        throw new Error('useBuyMembership must be used within a BuyMembershipProvider');
    }

    return context;
};
