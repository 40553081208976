import AnnualPassOption from './portal/annual-pass-option';
import { canPurchaseTicket } from 'app/utilities/buy-memberships';
import { Link } from 'react-router-dom';
import { MEMBERS_PORTAL_ROUTES } from 'app/utilities/routes';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
    DAY_PASS_OPTIONS,
    MEMBERSHIP_OPTIONS,
    MEMBERSHIP_TABS,
    TICKET_IMAGES
} from 'config/tickets-products';

import React, { useState } from 'react';

const TicketCategory = ({ image, children }) => (
    <div className="flexi-item ticket annual-pass">
        <div className="media" style={{ backgroundImage: `url(${image})` }}>
            <div className="preserve-image-ratio">
                <img className="fixed-image" src={image} alt="" />
            </div>
        </div>
        {children}
    </div>
);

TicketCategory.propTypes = {
    image: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};

const MembershipTabs = ({ activeTab, onTabChange }) => (
    <div className="membership-tabs">
        <button
            className={`tab-button ${activeTab === MEMBERSHIP_TABS.ANNUAL ? 'active' : ''}`}
            onClick={() => onTabChange(MEMBERSHIP_TABS.ANNUAL)}
        >
            Annual Pass
        </button>
        <button
            className={`tab-button ${activeTab === MEMBERSHIP_TABS.SIX_MONTH ? 'active' : ''}`}
            onClick={() => onTabChange(MEMBERSHIP_TABS.SIX_MONTH)}
        >
            Dinosaur Pass
        </button>
    </div>
);

MembershipTabs.propTypes = {
    activeTab: PropTypes.string.isRequired,
    onTabChange: PropTypes.func.isRequired
};

const TicketsSection = () => {
    const user = useSelector((state) => state.auth.user);
    const userCanBuyTicket = canPurchaseTicket(user);
    // default to dino pass
    const [activeTab, setActiveTab] = useState(MEMBERSHIP_TABS.SIX_MONTH);

    const renderDayPassCategory = () => (
        <TicketCategory image={TICKET_IMAGES.DAY_PASS}>
            <div className="title-group annual-pass-wrapper light">
                <div className="annual-pass-description">
                    <h2 className="title-group-heading">Day Pass</h2>
                    <div className="title-group-content">
                        <p className="ticket-category-description">
                            There&apos;s lots to see and do at Auckland Zoo, all year round! Thanks to the generous support from Auckland Council, we can offer a subsidised ticket price to visitors who live in Auckland and book a minimum of one day in advance. For all visitors, visiting us during off-peak times (<i>during NZ school terms</i>) means you pay less than peak time (<i>weekends, public and school holidays</i>).
                        </p>
                    </div>
                </div>
                <div className="annual-pass-options-wrapper">
                    {DAY_PASS_OPTIONS.map((option, index) => (
                        <div key={index} className={option.title.includes('Gift') ? 'day-pass-gift' : ''}>
                            <AnnualPassOption from="tickets-section" {...option} />
                        </div>
                    ))}
                </div>
            </div>
        </TicketCategory>
    );

    const renderAnnualPassCategory = () => (
        <TicketCategory image={TICKET_IMAGES.MEMBERSHIP}>
            <div className="title-group annual-pass-wrapper light">
                <div className="annual-pass-description">
                    <h2 className="title-group-heading">Membership</h2>
                    <h4 className="membership-heading">Choose your Membership:</h4>
                    <MembershipTabs activeTab={activeTab} onTabChange={setActiveTab} />
                    {activeTab === MEMBERSHIP_TABS.ANNUAL && (
                        <div className="title-group-content">
                            <p className="ticket-category-description">
                                Auckland Zoo&apos;s Annual Pass Membership gives you and your whānau unlimited entry to the Zoo 364 days a year and other great benefits. Plus, you&apos;ll be contributing to Auckland Zoo&apos;s conservation work at the Zoo and in the wild. <Link to="/visit/membership">More info</Link>
                            </p>
                        </div>
                    )}
                    {activeTab === MEMBERSHIP_TABS.SIX_MONTH && (
                        <div className="title-group-content">
                            {userCanBuyTicket &&
                                <p className="ticket-category-description">A Dinosaur Pass Membership gives you unlimited entry to the entire Zoo including the Dinosaur Discovery Track for six months from date of purchase. Be in quick, unlike the tuatara, this pass will go extinct soon!</p>
                            }
                            {!userCanBuyTicket &&
                                <p className="ticket-category-description">You already have an active membership which gives you unlimited entry to the entire Zoo including the Dinosaur Discovery Track. <Link to={MEMBERS_PORTAL_ROUTES.DASHBOARD}>Go to your dashboard</Link></p>
                            }
                        </div>
                    )}
                </div>
                <div className="annual-pass-options-wrapper">
                    {MEMBERSHIP_OPTIONS[activeTab].map((option, index) => (
                        <AnnualPassOption
                            key={index}
                            from="tickets-section"
                            {...option}
                            isVisible={option.title.includes('Become') ? userCanBuyTicket : true}
                        />
                    ))}
                </div>
            </div>
        </TicketCategory>
    );

    return (
        <section className="tickets-section || flexi-block">
            <div className="constrain-width large">
                <div className="inner">
                    {renderDayPassCategory()}
                    {renderAnnualPassCategory()}
                </div>
            </div>
        </section>
    );
};

export default TicketsSection;
