/* eslint-disable camelcase */
import { DAY_PASS_IDS } from 'config/tickets-categories';
import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import { formatMembershipProducts } from 'app/utilities/buy-memberships';
import { mergeMap  } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
export const INITIAL_STATE = {
    dayPass: undefined,
    membership: []
};


// Actions
export const INITIATE_DAY_PASS_PRODUCTS_FETCH = 'rfa-zoo-website/tickets/INITIATE_DAY_PASS_PRODUCTS_FETCH';
export const DAY_PASS_PRODUCTS_FETCH_SUCCESS = 'rfa-zoo-website/tickets/DAY_PASS_PRODUCTS_FETCH_SUCCESS';
export const MEMBERSHIP_PRODUCTS_FETCH_SUCCESS = 'rfa-zoo-website/tickets/MEMBERSHIP_PRODUCTS_FETCH_SUCCESS';

// Action Creators
export const initiateDayPassProductsFetchAction = () => ({
    type: INITIATE_DAY_PASS_PRODUCTS_FETCH
});

export const dayPassProductsFetchSuccessAction = (tickets) => ({
    type: DAY_PASS_PRODUCTS_FETCH_SUCCESS,
    payload: tickets
});

export const membershipProductsFetchSuccessAction = (response) => ({
    type: MEMBERSHIP_PRODUCTS_FETCH_SUCCESS,
    payload: response
});


// Reducers
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DAY_PASS_PRODUCTS_FETCH_SUCCESS:
            return dayPassProductsFetchSuccess(state, action.payload);
        case MEMBERSHIP_PRODUCTS_FETCH_SUCCESS:
            return membershipProductsFetchSuccess(state, action.payload);
        default:
            return state;
    }
};

function dayPassProductsFetchSuccess(state, tickets) { // eslint-disable-line require-jsdoc
    const dayPassData = getProductById(tickets, DAY_PASS_IDS);

    return {
        ...state,
        dayPass: dayPassData
    };
}

const membershipProductsFetchSuccess = (state, response) => {
// We now expect `response` to be an array of membership bundles
    const formattedMembership = response.map((bundle) => ({
        products: formatMembershipProducts(bundle.products), // Format the products within each bundle
        discounts: bundle.discounts, // Store the discounts array directly from the response
        name: bundle.name,
        label: bundle.label,
    }));

    return {
        ...state,
        membership: formattedMembership
    };
};


// Epic creator
export const createDayPassProductsEpic = (ticketsEndpoint, formatDayPassProducts, dayPassProductsFetchSuccessAction) => {
    return (action$) => action$.pipe(
        ofType(INITIATE_DAY_PASS_PRODUCTS_FETCH),
        mergeMap(() => {
            return (
                fetch(ticketsEndpoint)
                    .then((response) => {
                        const data = formatDayPassProducts(response);

                        return dayPassProductsFetchSuccessAction(data);
                    })
                    .catch(() => {
                    // TODO: Add proper error handling
                        return dayPassProductsFetchSuccessAction([]);
                    })
            );
        })
    );
};

// Epics
const dayPassProductsEpic = createDayPassProductsEpic(
    ENDPOINTS.TICKETS,
    formatDayPassProducts,
    dayPassProductsFetchSuccessAction
);

// Helpers

/**
 * Reformats the API response
 * @param {object} response
 * @returns {array} ticketsData - tickets array
 */
function formatDayPassProducts(response) {
    if (response && response.data && response.data.length) return response.data;

    return [];
}

function getProductById(products, productIdGroup) { // eslint-disable-line require-jsdoc
    return products.find((product) => productIdGroup.includes(product.id));
}

export const epics = combineEpics(dayPassProductsEpic);
