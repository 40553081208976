/* eslint-disable camelcase */
import moment from 'moment';
import {
    createValidationDataObject,
    isEmailValid,
    isPasswordLengthValid,
    isValueRequiredValid,
    membershipPassholdersDateOfBirthValidator
} from 'app/utilities/form-validation';
import { dateOfBirthFormat, displayDateOfBirthFormat } from './buy-memberships-passholder-details';

export const CREATE_ACCOUNT_FORM_FIELDS = [
    {
        name: 'first_name',
        label: 'First name',
        placeholder: '',
        type: 'text',
        defaultValue: '',
        isRequired: true,
        validator: (first_name) => {
            if (!isValueRequiredValid(first_name)) {
                return createValidationDataObject(false, 'This is a required field');
            }

            return createValidationDataObject();
        }
    },
    {
        name: 'last_name',
        label: 'Last name',
        placeholder: '',
        type: 'text',
        defaultValue: '',
        isRequired: true,
        validator: (last_name) => {
            if (!isValueRequiredValid(last_name)) {
                return createValidationDataObject(false, 'This is a required field');
            }

            return createValidationDataObject();
        }
    },
    {
        name: 'dob',
        label: 'Date of birth',
        rezdyLabel: 'Date of birth',
        placeholder: displayDateOfBirthFormat.toLowerCase(),
        type: 'date',
        defaultValue: '',
        isRequired: true,
        validator: (dob) => {
            if (!isValueRequiredValid(dob)) {
                return createValidationDataObject(false, 'This is a required field');
            }

            if (dob) {
                const date = moment(dob, dateOfBirthFormat, true);

                if (!date.isValid()) {
                    return createValidationDataObject(false, `Invalid date format: e.g. ${displayDateOfBirthFormat.toLowerCase()}`);
                }

                const { isValid, message } = membershipPassholdersDateOfBirthValidator('primaryAccountHolder', date);

                if (!isValid) {
                    return createValidationDataObject(false, message);
                }

                return createValidationDataObject();
            }

            return createValidationDataObject();
        }
    },
    {
        name: 'email',
        label: 'Email',
        placeholder: '',
        type: 'email',
        defaultValue: '',
        isRequired: true,
        validator: (email) => {
            if (!isValueRequiredValid(email)) {
                return createValidationDataObject(false, 'This is a required field');
            }

            if (email && !isEmailValid(email)) {
                return createValidationDataObject(false, 'Not a valid email address');
            }

            return createValidationDataObject();
        }
    },
    {
        name: 'password',
        label: 'Password',
        placeholder: '',
        type: 'password',
        defaultValue: '',
        isRequired: true,
        autocomplete: 'new-password',
        validator: (password) => {
            if (!isValueRequiredValid(password)) {
                return createValidationDataObject(false, 'This is a required field');
            }

            if (!isPasswordLengthValid(password)) {
                return createValidationDataObject(false, 'Password must contain at least 8 characters');
            }

            return createValidationDataObject();
        }
    },
    {
        name: 'password_confirmation',
        label: 'Confirm password',
        placeholder: '',
        type: 'password',
        defaultValue: '',
        isRequired: true,
        autocomplete: 'new-password',
        validator: (password_confirmation, password) => {
            if (!isValueRequiredValid(password_confirmation)) {
                return createValidationDataObject(false, 'This is a required field');
            }

            if (!isPasswordLengthValid(password_confirmation)) {
                return createValidationDataObject(false, 'Password must contain at least 8 characters');
            }

            if (password_confirmation !== password) {
                return createValidationDataObject(false, 'Passwords must match');
            }

            return createValidationDataObject();
        }
    },
    {
        name: 'marketing_opt_in',
        label: 'Yes, please keep me informed with first access to the latest Zoo news, exclusive member benefits, exciting events, and special giveaways!',
        placeholder: '',
        type: 'checkbox',
        isRequired: false,
        defaultValue: false,
        inputType: 'large',
        validator: () => {
            return createValidationDataObject();
        }
    },
];
