/* eslint-disable no-magic-numbers */
import * as yup from 'yup';
import moment from 'moment';

export const createValidationDataObject = (valid = true, message = undefined) => {
    return {
        valid,
        message
    };
};

export const isValueRequiredValid = (value) =>
    yup.string().trim().required().isValidSync(value);

export const isEmailValid = (value) => yup.string().trim().email().isValidSync(value);

export const isPasswordLengthValid = (password) =>
    yup.string()
        .min(8)
        .isValidSync(password);

export const membershipPassholdersDateOfBirthValidator = (productCategory, date) => {
    let isValid = true,
        message = '';

    switch (productCategory) {
        // Adult and Student >= 15 years old
        case 'adult':
        case 'student':
            if (moment().diff(date, 'years') < 15) {
                message = 'This person must be at least 15 years old';
                isValid = false;
            }
            break;
        // Senior >= 65 years old
        case 'senior':
            if (moment().diff(date, 'years') < 65) {
                message = 'This person must be at least 65 years old';
                isValid = false;
            }
            break;
        // Child >= 4 years old && <= 14 years old
        case 'child':
            if (
                moment().diff(date, 'years') > 14 ||
                moment().diff(date, 'years') < 4
            ) {
                message = 'This person must be between 4 years old and 14 years old';
                isValid = false;
            }
            break;
        // Cub <= 3 years old
        case 'cub':
            if (moment().diff(date, 'years') > 3 || moment().diff(date, 'days') < 0) {
                message = 'Cubs must be infants aged 3 years old or younger';
                isValid = false;
            }
            break;
        // Primary account holder >= 15 years old
        case 'primaryAccountHolder':
            if (moment().diff(date, 'years') < 15) {
                message = 'You must be 15 years or older to create an account';
                isValid = false;
            }
            break;
        default:
            // Not in future date
            if (moment().diff(date, 'days') < 0) {
                message = 'Invalid date';
                isValid = false;
            }
            break;
    }

    return {
        isValid,
        message
    };
};
