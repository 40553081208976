import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import QrCode from 'qrcode';
import React, { useEffect, useRef } from 'react';

// Append pass to the product label only if it's not a dinosaur pass
// eslint-disable-next-line require-jsdoc
function getProductLabel(customerNo, passRole) {
    const label = `${customerNo} - ${passRole}`;
    if (passRole.toLowerCase().includes('dinosaur pass')) {
        return label;
    }

    return `${label} Pass`;
}

const PassholderScanCard = ({ customerNo, passRole, name, imageUrl, scanable }) => {
    const qrCodeRef = useRef(null);

    const renderPhoto = (imageUrl) => {
        const img = imageUrl
            ? <img src={imageUrl} alt="Passholder" />
            : <Icon name="user-circle-default" width="200" height="200" title="" />;

        return <div className="pass-scan-card-avatar">{ img }</div>;
    };

    useEffect(() => {
        QrCode.toCanvas(qrCodeRef.current, [{ data: customerNo, mode: 'alphanumeric' }], {
            // adjust scale to fit the qrcode version
            version: 2,
            scale: 7
        });
    });

    return (
        <div className="pass-scan-card">
            <div className="pass-scan-card-details">
                {renderPhoto(imageUrl)}
                <div className="pass-scan-card-text">
                    <p className="pass-scan-card-text-name">{name}</p>
                    <p className="pass-scan-card-text-role">{getProductLabel(customerNo, passRole)}</p>
                </div>
            </div>
            <div className="pass-scan-card-qrcode">
                {scanable && <canvas ref={qrCodeRef}></canvas>}
                {!scanable && <p>Please upload your profile photo first</p>}
            </div>
        </div>
    );
};

PassholderScanCard.propTypes = {
    customerNo: PropTypes.string.isRequired,
    passRole: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    scanable: PropTypes.bool.isRequired
};

export default PassholderScanCard;
