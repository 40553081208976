import { FRONTEND_ROUTES } from 'app/utilities/routes';
import { isAppProd } from 'config/env';

export const DAY_PASS = {
    presale: {
        productCodeDev: 'PQN0PR',
        productCodeProd: ''
    },
    sameday: {
        productCodeDev: 'PUREAQ',
        productCodeProd: 'PRLKGV',
        priceOptions: {
            adult2AndChildren1: {
                idDev: 33574,
                idProd: 4207738
            },
            adult2AndChildren2: {
                idDev: 33573,
                idProd: 4207736
            },
            infant: {
                idDev: 30554,
                idProd: 4207737
            }
        }
    }
};

export const MEMBERSHIP_TABS = {
    ANNUAL: 'annual',
    SIX_MONTH: 'sixmonth',
};

export const TICKET_IMAGES = {
    DAY_PASS: 'https://cdn.aucklandunlimited.com/zoo/assets/media/day-pass-background.jpeg',
    MEMBERSHIP: 'https://cdn.aucklandunlimited.com/zoo/assets/media/giraffe-kiraka-pregnant-gallery-4.jpg'
};

export const DAY_PASS_OPTIONS = [
    {
        title: 'I live in Auckland',
        price: '19',
        image: 'https://cdn.aucklandunlimited.com/zoo/assets/static/AZ_Ticketing_Image+Tiles_NZ+Resident.jpg',
        link: isAppProd
            ? 'https://tickets.aucklandzoo.co.nz/events/27f13cef-509f-984e-4b34-3dd9f91bdb99?tg=19aed43f-63d4-643c-741e-e4f342b15023&tg=eb19e667-9921-3fb5-b21e-a102420a05eb'
            : 'https://aucklandzoo.web.d1tix.com/phase2/events/0188d23c-0465-5cb7-2c64-44ea6d869608?tg=0188d23c-046b-4fd0-4b33-d6ef176acc9c&tg=0188d23c-046b-124a-061d-f52bfe72eb18'
    },
    {
        title: 'I do not live in Auckland',
        price: '29',
        image: 'https://cdn.aucklandunlimited.com/zoo/assets/static/AZ_Ticketing_Image+Tiles_International.jpg',
        link: isAppProd
            ? 'https://tickets.aucklandzoo.co.nz/events/27f13cef-509f-984e-4b34-3dd9f91bdb99?tg=66f6b6c3-79c5-c443-e895-8472b169a5b7&tg=bb25b079-f93c-cfcf-b46c-061af61f9359'
            : 'https://aucklandzoo.web.d1tix.com/phase2/events/0188d23c-0465-5cb7-2c64-44ea6d869608?tg=0188d23c-046b-b9c1-308a-9fdda1b85c07&tg=d01cbb4b-7a8d-6fe0-b041-8a718d21788c'
    },
    {
        title: 'Gift a Day Pass',
        subtitle: 'For NZ residents only',
        price: '24',
        image: 'https://cdn.aucklandunlimited.com/zoo/assets/static/AZ_Ticketing_Image+Tiles_Gift+Day+Pass.jpg',
        link: 'https://aucklandzoo.rezdy.com/358483/gift-general-admission'
    }
];

export const MEMBERSHIP_OPTIONS = {
    [MEMBERSHIP_TABS.ANNUAL]: [
        {
            title: 'Become a member',
            price: '49',
            image: 'https://cdn.aucklandunlimited.com/zoo/assets/static/AZ_Ticketing_Image+Tiles_Become+a+Member.jpg',
            link: '/select-pass'
        },
        {
            title: 'Renew a membership',
            price: '36.75',
            image: 'https://cdn.aucklandunlimited.com/zoo/assets/static/AZ_Ticketing_Image+Tiles_Renew+Membership.jpg',
            link: FRONTEND_ROUTES.RENEW_MEMBERSHIP,
            mediaStyle: { 'background-position-y': '20%' }
        },
        {
            title: 'Gift a membership',
            price: '49',
            image: 'https://cdn.aucklandunlimited.com/zoo/assets/static/AZ_Ticketing_Image+Tiles_Gift+Membership.jpg',
            link: FRONTEND_ROUTES.GIFT_MEMBERSHIP,
            mediaStyle: { 'background-position-y': '20%' }
        }
    ],
    [MEMBERSHIP_TABS.SIX_MONTH]: [
        {
            title: 'Become a Dinosaur Pass member',
            price: '30',
            image: 'https://cdn.aucklandunlimited.com/zoo/assets/media/az-dino-membership-image-tile_mainImage.jpg',
            link:  '/select-pass',
        },
    ]
};
