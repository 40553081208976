/* eslint-disable camelcase */
import _ from 'lodash';
import Big from 'big.js';
import { convertToSentenceCasing } from 'app/utilities/format-text';
import { createFormFieldsDetail } from 'config/buy-memberships-passholder-details';
import { fireEcommerceEvent } from 'app/utilities/gtm';

export const validateSelectedProducts = (products) => {
    const errors = {};

    if (!products || products.length === 0) {
        errors.products = 'No products selected';

        return errors;
    }

    // Cub cannot be bought buy itself
    if (products.every(({ product_category }) => product_category === 'cub')) {
        errors.cub = 'Please add a named passholder or an adult guest to progress with a cub membership. Anyone that\'s 14 years and under must be supervised by a responsible adult aged 15 years or over.';
    }

    return errors;
};

export const sanitizeSelectedProducts = (products) => {
    if (!products.length) {
        return products;
    }

    const namedProducts = products.filter(({ is_guest }) => !is_guest);
    const guestProducts = products.filter(({ is_guest }) => is_guest);

    // Clear guests and support person if there are no named passholders
    if (!namedProducts.length) {
        return [];
    }

    if (namedProducts.every(({ product_category }) => product_category === 'cub')) {
        // max 1 cub with 1 adult guest (if there are no other paying passholders)
        const cub = namedProducts[0];
        cub.quantities = 1;

        // disallow support person if there are only cub + adult guest
        const allowedGuests = guestProducts.filter(({ product_category }) => product_category !== 'support');

        return [...allowedGuests, cub];
    }

    return products;
};

export const formatMembershipProducts = (products) => {
    return products.map((product, index) => ({
        ...product,
        product_index: index,
    }));
};

// Get unit price after discount percentage
export const getUnitPrice = (price, discountPct = 0) => {
    return Big(price).times(Big(1).minus(discountPct)).toFixed(2);
};

// To avoid subtle bugs caused by floating point arithmetic
// always use arbitrary precision arithmetic to handle money calculations
export const getCosts = (productsSelected, discounts, renewDiscountPct = 0, extraDiscount = 0) => {
    const totalCosts = productsSelected.reduce((prev, current) => {
        const unitPrice = getUnitPrice(current.price, renewDiscountPct);

        return prev.plus(Big(unitPrice).times(current.quantities));
    }, Big(0));

    const discountPct = discounts
        .sort((current, next) => Big(current.min).minus(next.min).toNumber())
        .reduce((prev, current) => {
            if (totalCosts.gte(current.min)) return Big(current.discount);

            return Big(prev);
        }, 0);

    // Extra discount: ga discount etc etc
    const totalExtraDiscount = extraDiscount ?
        Object.keys(extraDiscount).reduce((prev, current) => prev.plus(Big(extraDiscount[current].discount)), Big(0)) : 0;

    // Scalling discount only
    const saveCosts = totalCosts.times(discountPct).round(2);

    // Scalling discount + extra discount(ga)
    const costsAfterDiscount = totalCosts.minus(saveCosts).minus(totalExtraDiscount).round(2);

    const res = {
        totalCosts: totalCosts.toFixed(2),
        saveCosts: saveCosts.toFixed(2),
        costsAfterDiscount: costsAfterDiscount.toFixed(2)
    };

    return res;
};

// Convert to a human readable lable (bundle type agnostic)
const convertProductCategoryToLabel = (category) => {
    switch (category) {
        case 'adult-guest':
            return 'Adult Guest';
        case 'support':
            return 'Support Person';
        default:
            return category;
    }
};

const generateProductInfo = (productOrCategory, quantities, useCategory = false) => {
    const label = useCategory ? convertProductCategoryToLabel(productOrCategory) : productOrCategory;
    const isMultiSelected = quantities > 1;
    // Convert to sentence casing
    const displayName = label
        .split(' ')
        .map((text) => convertToSentenceCasing(text))
        .join(' ');
    let info = `${quantities} ${displayName}`;

    if (!isMultiSelected) return info;

    switch (productOrCategory.toLowerCase()) {
        case 'child':
            info = `${quantities} Children`;
            break;
        default:
            info = `${info}s`;
    }

    return info;
};

// Caller to specify if product name or category should be used
export const generatePassholdersDisplayInfo = (products, useCategory = false) => {
    let displayInfo =  '';

    if (products && products.length > 0) {
        const productsSelectedInfo = products.map(({ product, product_category, quantities }) =>
            generateProductInfo(useCategory ? product_category : product, quantities, useCategory));

        displayInfo = productsSelectedInfo.length < 2 ?
            productsSelectedInfo[0] :
            `${productsSelectedInfo.slice(0, -1).join(', ')} and ${productsSelectedInfo.slice(-1)}`;
    }

    return displayInfo;
};

export const getAnnualPass = (passes, id) => {
    for (const pass of passes) {
        if (pass.id === id) {
            return pass;
        }
    }

    return null;
};

// Format order pricing details from receipt info given by backend
export const generateOrderPricing = (receiptInfo) => {
    return {
        totalCosts: receiptInfo.gross_total.toFixed(2),
        saveCosts: receiptInfo.discount.toFixed(2),
        costsAfterDiscount: receiptInfo.net_total.toFixed(2),
        // format of products-selected-list displayData
        priceRows: receiptInfo.price_detail.map((row) => {
            return {
                label: row.product,
                personName: row.name,
                price: row.price.toFixed(2)
            };
        })
    };
};

export const canRenew = (user) => {
    // user not login cannot renew
    if (!user) return false;
    const annualPass = user.annual_passes[0];

    return annualPass && annualPass.can_renew;
};

export const canPurchaseTicket = (user) => {
    // user not login can purchase ticket
    if (user && user.annual_passes.length > 0) {
        const activePasses = user.annual_passes
            .filter((pass) => pass.status !== 'cancelled' && pass.status !== 'expired');

        return activePasses.length === 0;
    }

    return true;
};

export const canRedeemDaypassDiscount = (isGifting, isDefaultMembership) => {
    /*
    Conditions to show redeem day pass discount input:
    - Not gifting
    - Not for other passes
    */
    return !isGifting && isDefaultMembership;
};

export const generatePassholdersFormData = (products) => {
    return products.reduce((
        prev,
        { product, description, quantities, is_guest: isGuest, product_category }
    ) => {
        if (!isGuest) {
            prev.push({
                product,
                description,
                formFieldsGroup: new Array(quantities).fill(createFormFieldsDetail(product_category))
            });
        }

        return prev;
    }, []);
};

export const generatePassHoldersData = (passholdersFormValue) => {
    return passholdersFormValue.reduce((prev, { product, formFields }) => {
        const formData = formFields.reduce((prev, { fieldName, value }) => {
            prev[fieldName] = value;

            return prev;
        }, {});

        prev.push({ ...formData, product });

        return prev;
    }, []);
};


// Differentiates between new membership, renewal, and gift membership
export const resolveEcommerceItemName = (renewPassId = null, isGifting = false) => {
    if (isGifting) {
        return 'Gift Membership';
    } else if (renewPassId) {
        return 'Renew Membership';
    }

    return 'New Membership';
};

export const resolveEcommerceItemId = (renewPassId = null, isGifting = false) => {
    if (isGifting) {
        return 'annual_pass_gift-membership';
    } else if (renewPassId) {
        return 'annual_pass_renew-membership';
    }

    return 'annual_pass_new-membership';
};

// Format data for ecommerce event from productsSelected redux state
export const generateCartForEcommerce = (productsSelected, renewPassId = null, isGifting = false) => {
    const name = resolveEcommerceItemName(renewPassId, isGifting);

    return productsSelected.map(({ product, price, quantities }) => ({
        item_id: resolveEcommerceItemId(renewPassId, isGifting),
        item_name: name,
        item_category: 'Annual Pass',
        item_category2: name,
        item_category3: product,
        price,
        quantity: quantities,
    }));
};

// Format data for ecommerce event from windcave notification response (windcave_transactions.order_data.receipt_info)
export const generateCartFromReceipt = (receiptInfo, renewPassId = null, isGifting = false) => {
    const name = resolveEcommerceItemName(renewPassId, isGifting);

    const productCounts = _.countBy(receiptInfo.price_detail, 'product');

    const products = _.keyBy(receiptInfo.price_detail, 'product');

    return Object.entries(productCounts).map(([product, count]) => ({
        item_id: resolveEcommerceItemId(renewPassId, isGifting),
        item_name: name,
        item_category: 'Annual Pass',
        item_category2: name,
        item_category3: product,
        price: products[product].price,
        quantity: count,
    }));
};

/* GTM events */
// Review and payment steps
export const fireCartEvent = (event, productsSelected, totalPrice = null, renewPassId = null, isGifting = false) => {
    const payload = {
        currency: 'NZD',
        items: generateCartForEcommerce(productsSelected, renewPassId, isGifting),
    };

    // total price after discounts
    if (totalPrice !== null) {
        payload.value = totalPrice;
    }
    fireEcommerceEvent(event, payload);
};

// Purchase completed successfully
// orderData is hydrated from backend
export const firePurchaseCompletedEvent = (orderData, txnId) => {
    const isGifting = orderData.gift_code !== undefined;
    const payload = {
        transaction_id: txnId, // windcave_transactions.txn_id
        currency: 'NZD',
        value: orderData.total_amount, // total price after discounts
        tax: parseFloat(orderData.total_amount) * (0.15 / 1.15),
        shipping: 0,
        items: generateCartFromReceipt(orderData.receipt_info, orderData.renew_pass_id, isGifting),
    };
    fireEcommerceEvent(EVENT_PURCHASE, payload);
};

export const EVENT_VIEW_CART = 'view_cart';
export const EVENT_ADD_TO_CART = 'add_to_cart';
export const EVENT_REMOVE_FROM_CART = 'remove_from_cart';
export const EVENT_BEGIN_CHECKOUT = 'begin_checkout';
export const EVENT_ADD_SHIPPING_INFO = 'add_shipping_info';
export const EVENT_PURCHASE = 'purchase';
export const TICKETS_TYPE_PARAM = 'tickets_type';
export const TICKETS_TYPE_DEFAULT_MEMBERSHIP = 'membership';
export const TICKETS_TYPE_DINO = 'dino';
