import * as Yup from 'yup';
import AuthCredentials from 'app/components/partials/portal/auth/credentials';
import BgImage from 'static/images/login-bg.jpg';
import { clientEnv } from 'config/env';
import { clientFetch } from 'app/utilities/fetch';
import Input from 'app/components/partials/input';
import Loader from 'app/components/partials/loader';
import RecaptchaPolicy from 'app/components/partials/recaptcha-policy';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { ENDPOINTS, ERROR_MESSAGE, RESPONSE } from 'config/api';
import React, { useEffect } from 'react';

const ForgotPasswordPage = () => {
    const location = useLocation();

    const submitForm = async(values, captchaToken, setStatus, setSubmitting) => {
        try {
            const response = await clientFetch(ENDPOINTS.PORTAL_SEND_RESET_PASSWORD_LINK, {
                method: 'POST',
                body: JSON.stringify({
                    email: values.email,
                    captchaToken,
                })
            });

            const successMessage =
                response &&
                response.message ||
                'The link has been sent, please check your email to reset your password.';

            setStatus({ success: successMessage });
        } catch (err) {
            const { error, status } = err;

            const errorMessage =
                status === RESPONSE.BAD_REQUEST &&
                error &&
                error.error &&
                error.error.message || ERROR_MESSAGE.DEFAULT;

            setStatus({ error: errorMessage });
        } finally {
            setSubmitting(false);
        }
    };

    const formik = useFormik({
        initialValues: { email: '' },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required('This is a required field')
        }),
        onSubmit: async(values, { setStatus, setSubmitting }) => {
            setStatus();
            // eslint-disable-next-line no-undef
            const token = await grecaptcha.execute(clientEnv.RECAPTCHA_KEY, { action: 'forgot_password' });
            // Pass recaptcha verify token for backend to validate
            await submitForm(values, token, setStatus, setSubmitting);
        },
    });

    useEffect(() => {
        const { state } = location;

        if (state && state.tokenAndEmailVerifyError) formik.setStatus({ error: state.tokenAndEmailVerifyError });
    }, []);

    return (
        <AuthCredentials title="Reset Password" imgSrc={BgImage}>
            <h2 className="title || heading-2">Reset Password</h2>
            <form className="form || forgot-password-form" onSubmit={formik.handleSubmit} method="POST">
                <div className="section">
                    <Input
                        label="Email address"
                        type="email"
                        className="input"
                        name="email"
                        id="email"
                        placeholder=""
                        value={formik.values.email}
                        onChangeHandler={formik.handleChange}
                        onBlurHandler={formik.handleBlur}
                        isRequired
                        error={formik.touched.email && formik.errors.email}
                    />
                </div>
                {formik.status && formik.status.error && <div className="error-message">{formik.status.error}</div>}
                {formik.status && formik.status.success && <div className="success-message">{formik.status.success}</div>}
                <RecaptchaPolicy />
                <button className="button primary || submit-btn" type="submit" disabled={formik.isSubmitting}>{
                    formik.isSubmitting ? <Loader type="small" /> : 'Send password reset link'
                }</button>
            </form>
        </AuthCredentials>
    );
};

export default ForgotPasswordPage;
